import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
export default function SecretariatReportsComponent() {
  return (
    <>
      <br></br>
      <div className="container">
        <div className="row">
          <div className="col-md-6 text-left my-auto mt-3">
            <p className="dashboard mb-0 ml-1">Reports</p>
          </div>
        </div>
        <br />
        <div className="app-inside-content">
          <div className="row col-md-12">
            <Link
              to="/monthlyreport"
              style={{
                textDecoration: "none",
                display: "block",
                width: "100%",
              }} // Ensure the Link behaves like a block element and fills the width
            >
              <div
                className="col-md-4 card p-4 my-3"
                style={{
                  backgroundColor: "#f0f4f8", // Light background color
                  backgroundPosition: "right 20px bottom 20px", // Position the icon at bottom right
                  backgroundRepeat: "no-repeat", // Do not repeat the icon
                  backgroundSize: "50px 50px", // Adjust the size of the icon
                  borderRadius: "10px", // Rounded corners
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow
                  color: "#333", // Text color
                  width: "100%", // Make sure the card takes up full width within the column
                }}
              >
                <h5 style={{ fontWeight: "600", textAlign: "center" }}>
                Monthly Startup Applications Report
                </h5>
              </div>
            </Link>
          </div>{" "}
          <br></br> <br></br> <br></br> <br></br>
          <br></br> <br></br> <br></br> <br></br>
          <br></br> <br></br> <br></br> <br></br>
          <br></br> <br></br> <br></br> <br></br>
          <br></br> <br></br> <br></br> <br></br>
          <br></br> <br></br> <br></br> <br></br>
        </div>
      </div>
    </>
  );
}
