import React, { useEffect, useState } from "react";
import Stepper from "bs-stepper";
import { SaveRequestModel } from "../../../models/incubator/SaveRequestModel";
import { post } from "../../../effects/api/api.common";
import { STORE_INCUBATOR_APPLICATION } from "../../../constants/api";
import IncrementDecrement from "../../common/controls/IncrementDecrement";
import { requestType, StepperEnum } from "../../../utils/Enums";
import {
  validateNumber,
  validateRequiredText,
} from "../../../effects/utils/validations";
import { FundReapplyDtlsModel } from "../../../models/incubator/FundReapplyDtlsModel";
import { useHistory } from "react-router-dom";
interface IIncubatorFundDetails {
  stepper: any;
  fundDetailsModel: FundReapplyDtlsModel;
  onStepSave: (
    stepperIndex: number,
    fundsModel: FundReapplyDtlsModel,
    request: number,
    oldStatus: any
  ) => void;
  errors: Array<string>;
  applicationId: any;
  disabled: boolean;
  oldStatus: any;
}
function FundRequiremntDtls(props: IIncubatorFundDetails) {
  const { stepper, fundDetailsModel, onStepSave, disabled, applicationId,oldStatus } =
    props;
  const [agreeTermsBlock, setAgreeTermsBlock] = useState(false);
  const [fundDetails, setFundDetails] = useState<FundReapplyDtlsModel>(
    new FundReapplyDtlsModel("", "", "", "", "")
  );
  useEffect(() => {
    setFundDetails(fundDetailsModel);
  }, []);
  const history = useHistory();
  const applicationPage = () => {
    history.push({
      pathname: "/incubator_status",
    });
  };
  const handleBlur = () => {
    const amount = parseInt(fundDetails.quantum_fund_required_re_apply);
    if (amount > 50000000) {
      const validationError = validateNumber(amount, 50000000);
      //console.log(validationError);
    }
  };
  const handleChange = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    let value = evt.target.value
      .replace(/[^0-9.]/gi, "")
      .toString()
      .split(".")
      .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
      .join(".");
    let decimalCount = value.split(".").length - 1;
    if (decimalCount > 1) {
      value = value.split(".").slice(0, 2).join(".");
    }
    let tempFundDetails = JSON.parse(JSON.stringify(fundDetails));
    tempFundDetails[name] = value;
    setFundDetails(tempFundDetails);
    setFundDetails({ ...fundDetails, [name]: value });
  };
  const onSubmit = (action?: string) => {
    fundDetails.action = action === "submit" ? action : "";
    onStepSave(StepperEnum.FundUtilization, fundDetails, requestType.submit,oldStatus);
  };
  const handleOnCheck = (e: any) => {
    const isChecked = e.target.checked;
    setAgreeTermsBlock(isChecked);
    setFundDetails((prevState: FundReapplyDtlsModel) => {
      return {
        ...prevState,
        [e.target.name]: e.target.checked,
      };
    });
  };
  const handleIncrementDecrementChange = (name: string, value: number) => {
    setFundDetails((prevState: FundReapplyDtlsModel) => {
      return {
        ...prevState,
        [name]: value <= 0 ? 0 : value,
      };
    });
  };
  return (
    <div
      id="incubator-step-4"
      role="tabpanel"
      className="bs-stepper-pane content"
      aria-labelledby="stepper1trigger4"
    >
      <div className="form-wrapper stepper-form stepper-form-4">
        <div className="form-content">
          <div className="form-group required row">
            <label className="col-sm-4 col-form-label">
              Quantum of fund required
            </label>
            <div className="col-sm-4">
              <input
                maxLength={8}
                name="quantum_fund_required_re_apply"
                value={
                  fundDetails.quantum_fund_required_re_apply == 0
                    ? null
                    : fundDetails.quantum_fund_required_re_apply
                }
                className={`${
                  props.errors.length &&
                  validateNumber(
                    parseInt(fundDetails.quantum_fund_required_re_apply),
                    50000000
                  )
                    ? `form-control error`
                    : "form-control"
                }`}
                onBlur={handleBlur}
                onChange={handleChange}
                required={true}
              />
              <small>Max. &#8377; 5,00,00,000</small>
              <div
                className={
                  `${
                    props.errors.length &&
                    validateNumber(
                      parseInt(fundDetails.quantum_fund_required_re_apply),
                      50000000
                    )
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                  }` ||
                  `${validateNumber(
                    parseInt(fundDetails.quantum_fund_required_re_apply),
                    50000000
                  )}`
                }
              ></div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">
              Expected number of fund beneficiaries in year 1
            </label>
            <div className="col-sm-4">
              <IncrementDecrement
                name="no_fund_benifitiaries_1yr_re_apply"
                value={
                  fundDetails.no_fund_benifitiaries_1yr_re_apply
                    ? fundDetails.no_fund_benifitiaries_1yr_re_apply == null
                      ? 0
                      : fundDetails.no_fund_benifitiaries_1yr_re_apply
                    : 0
                }
                onChange={(name: string, value: number) =>
                  handleIncrementDecrementChange(
                    "no_fund_benifitiaries_1yr_re_apply",
                    value
                  )
                }
              />
            </div>
          </div>
          <div className="form-group  row">
            <label className="col-sm-4 col-form-label">
              Expected number of fund beneficiaries in year 2
            </label>
            <div className="col-sm-4">
              <IncrementDecrement
                name="no_fund_benifitiaries_2yr_re_apply"
                value={
                  fundDetails.no_fund_benifitiaries_2yr_re_apply
                    ? fundDetails.no_fund_benifitiaries_2yr_re_apply == null
                      ? 0
                      : fundDetails.no_fund_benifitiaries_2yr_re_apply
                    : 0
                }
                onChange={(name: string, value: number) =>
                  handleIncrementDecrementChange(
                    "no_fund_benifitiaries_2yr_re_apply",
                    value
                  )
                }
                minValue={0}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-4 col-form-label">
              Expected number of fund beneficiaries in year 3
            </label>
            <div className="col-sm-4">
              <IncrementDecrement
                name="no_fund_benifitiaries_3yr_re_apply"
                value={
                  fundDetails.no_fund_benifitiaries_3yr_re_apply
                    ? fundDetails.no_fund_benifitiaries_3yr_re_apply == null
                      ? 0
                      : fundDetails.no_fund_benifitiaries_3yr_re_apply
                    : 0
                }
                onChange={(name: string, value: number) =>
                  handleIncrementDecrementChange(
                    "no_fund_benifitiaries_3yr_re_apply",
                    value
                  )
                }
                minValue={0}
              />
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-12">
            <div className="agree-confirm-box">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="agreeincubatorapplication"
                  name="agreeincubatorapplication"
                  checked={disabled ? true : agreeTermsBlock}
                  onChange={handleOnCheck}
                />
                <label
                  className="custom-control-label"
                  htmlFor="agreeincubatorapplication"
                >
                  We are in compliance with the provisions of the various Acts,
                  Rules, Regulations, Guidelines, Standards applicable to the
                  entity from time to time. All information provided by us in
                  the application is true, correct and complete and no
                  information material to the subject matter of this form has
                  been suppressed or concealed. If there is any change in this
                  information or compliance status, I will duly inform Startup
                  India within 15 days on{" "}
                  <a href="mailto:dipp-startups@nic.in">
                    <u className="link-orange-text">dipp-startups@nic.in</u>
                  </a>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="stepper-footer-section">
          <div>
            <div
              className="btn-toolbar justify-content-between"
              role="toolbar"
              aria-label="Toolbar with button groups"
            >
              <button
                className="btn btn-sisfs btn-transparent-primary"
                onClick={() => applicationPage()}
              >
                Cancel
              </button>
              <span className="stepper-page-count" style={{ margin: "auto" }}>
                Step 2 / 2
              </span>

              <div className="btn-group">
                <button
                  className="btn btn-sisfs btn-transparent-primary"
                  onClick={() => stepper && stepper.previous()}
                >
                  Previous
                </button>
                <button
                  className="btn btn-sisfs btn-yellow-primary"
                  onClick={() => onSubmit("submit")}
                  disabled={!agreeTermsBlock}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FundRequiremntDtls;
