import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  BsFileEarmarkExcelFill,
  BsClockFill,
  BsListStars,
} from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa"; // Import Font Awesome for the dropdown icon

import Calendar from "../common/controls/Calendar";
import { MONTHLY_REPORT, VIEW_HISTORY, NODE_URL } from "../../constants/api";
import { post, get } from "../../effects/api/api.common";
import { getSessionStorageValue } from "../../effects/utils/session-storage";
import back from "../../images/back.svg";
import moment from "moment";
interface HistoryItem {
  i_ts?: string; // Adjust this to the actual property name in your response
  name?: string;
  email?: string;
}
declare var $: any;

export default function MonthlyReportComponent() {
  const [selectedDate, setSelectedDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [error, setError] = useState({
    startDate: false,
    endDate: false,
  });
  const [loader, setLoader] = useState(false);
  const [MonthlyReportData, setMonthlyReportData] = useState([]);
  const [showmesage, setshowmesage] = useState(false);
  const [ViewHistoryData, setViewHistoryData] = useState<HistoryItem[]>([]);
  const [lastItem, setLastItem] = useState<HistoryItem | null>(null); // Properly typed
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Change this to however many items you want per page
  const totalPages =
    ViewHistoryData && ViewHistoryData.length > 0
      ? Math.ceil(ViewHistoryData.length / itemsPerPage)
      : 0;

  // Get the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    ViewHistoryData && ViewHistoryData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (direction: "next" | "prev") => {
    if (direction === "next" && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const handleDateChange = (date: any, dateType: "startDate" | "endDate") => {
    setSelectedDate((prevDates) => ({
      ...prevDates,
      [dateType]: date,
    }));
    if (dateType === "startDate" && date)
      setError((prev) => ({ ...prev, startDate: false }));
    if (dateType === "endDate" && date)
      setError((prev) => ({ ...prev, endDate: false }));
  };
  const formatDate = (date: any) => {
    if (!date) return null;
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user);
  const validateAndFetchReport = () => {
    // Check if both dates are selected
    const { startDate, endDate } = selectedDate;
    let hasError = false;

    if (!startDate) {
      setError((prev) => ({ ...prev, startDate: true }));
      hasError = true;
    }
    if (!endDate) {
      setError((prev) => ({ ...prev, endDate: true }));
      hasError = true;
    }

    // If no validation errors, call the API
    if (!hasError) {
      fetchMonthlyReport(startDate, endDate);
    }
  };
  const fetchMonthlyReport = async (startDate: any, endDate: any) => {
    const payload = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      logged_user_id: userInfo.id,
    };

    setLoader(true); // Show loading state

    try {
      const response: any = await post(MONTHLY_REPORT, payload);
      setLoader(false); // Hide loading state

      // Check if the response is valid
      if (!response || !response.data) {
        setshowmesage(true); // Show message if response is empty
        return;
      }

      if (
        response.result === "Success" &&
        response.message === "No data available for the selected period"
      ) {
        setshowmesage(true); // Show message if no data is available
        return;
      }

      const filePath = response.data.filePath;
      if (!filePath) {
        setshowmesage(true); // Show message if filePath is undefined
        return;
      }

      // Use window.open to download the file in a new tab
      const downloadUrl = `${NODE_URL}/${filePath}`;
      window.open(downloadUrl, "_blank"); // Opens the file download in a new tab

      setshowmesage(false); // Hide message if everything goes well
      $("#generatereport").modal("hide"); // Hide modal
      setSelectedDate({ startDate: null, endDate: null }); // Reset selected dates
      setError({ startDate: false, endDate: false }); // Clear errors

      // Refresh the page after downloading the file
      window.location.reload();
    } catch (error) {
      console.error("Error fetching monthly report:", error);
      setshowmesage(true); // Show error message
      $("#generatereport").modal("hide"); // Hide modal
      window.location.reload(); // Reload the page on error
    }
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const handleSelection = (month: any) => {
    setSelectedMonth(month);
    setShowDropdown(false); // Close the dropdown
    fetchLastMonthReport(month); // Pass the selected month to the method
  };
  const lastTwoMonths = [
    moment().subtract(1, "months").format("MMMM YYYY"), // Last month
    moment().subtract(2, "months").format("MMMM YYYY"), // Month before last
  ];

  // Store them in one variable
  const lastMonths = lastTwoMonths.join(" & ");

  const fetchLastMonthReport = async (month: any) => {
    console.log("Fetching report for:", month);
  
    const [selectedMonthName, selectedYear] = month.split(" ");
    const selectedMonth = new Date(Date.parse(selectedMonthName + " 1, 2023")).getMonth();
    const year = parseInt(selectedYear);
  
    const startDate = "2021-07-19";
    const endDate = new Date(year, selectedMonth + 1, 0);
    
    const payload = {
      startDate: startDate,
      endDate: formatDate(endDate),
      logged_user_id: userInfo.id,
    };
  
    setLoader(true);
    console.log(payload);
  
    try {
      const response: any = await post(MONTHLY_REPORT, payload);
      setLoader(false);
  
      if (!response || !response.data) {
        setshowmesage(true);
        return;
      }
  
      if (response.result === "Success" && response.message === "No data available for the selected period") {
        setshowmesage(true);
        return;
      }
  
      const filePath = response.data.filePath;
      if (!filePath) {
        setshowmesage(true);
        return;
      }
  
      setMonthlyReportData(filePath);
      console.log(filePath);
  
      // Construct the full download URL
      const downloadUrl = `${NODE_URL}/${filePath}`;
  
      // Create a temporary <a> element for download
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", filePath.split("/").pop()); // Use the filename from filePath
      document.body.appendChild(link);
      link.click();
      link.remove(); // Clean up after the download
      setshowmesage(false);
      // window.location.reload();
    } catch (error) {
      console.error("Error fetching monthly report:", error);
      setshowmesage(true);
    }
  };
  

  useEffect(() => {
    ViewHistory();
  }, []);
  const ViewHistory = () => {
    get(VIEW_HISTORY).then((response: any) => {
      if (!response || !response.data) return;
      console.log(response.data.data);
      const historyData = response.data.data;

      if (response?.data?.result === "success") {
        setViewHistoryData(historyData);
        // Get the last item directly from the response data, not the state
        const lastItemFromResponse =
          historyData.length > 0 ? historyData[0] : null;
        setLastItem(lastItemFromResponse); // Set the last item in the state
      } else {
        setViewHistoryData([]);
        setLastItem(null);
      }
    });
  };
  const formatDate1 = (timestamp: any) => {
    const date = new Date(timestamp);

    // Get components of the date and time using UTC
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    // Return formatted date and time in UTC
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <>
      {loader && (
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
      <br />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2 text-left my-auto mt-3">
            <Link to={{ pathname: "/secretariatreports" }}>
              <img
                src={back}
                alt="DIPP"
                className="portImage"
                style={{
                  height: "30px",
                }}
              />
            </Link>
          </div>

          <div className="col-md-8 mt-3 d-flex justify-content-center align-items-center">
            <h5 style={{ fontWeight: "700" }}>
              Monthly Startup Applications Report
            </h5>
          </div>
        </div>
        <br /> <br />
        <div className="app-inside-content">
          <div className="d-flex flex-wrap">
            {[
              "Application No.",
              "Name of Startup",
              "Recognition Number",
              "Submitted Date",
              "Evaluation Date",
              "Final Status",
              "Sector",
              "Industry",
              "City",
              "State",
              "Associated Incubator",
              "Funding Instrument",
              "Total Funds Approved",
              "Total Funds Disbursed",
              "Date of Cancellation",
            ].map((title, index) => (
              <div
                key={index}
                className="card my-2 mx-1"
                style={{
                  flex: "1 1 18%",
                  padding: "10px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <h6 style={{ fontWeight: "600", textAlign: "center" }}>
                  {title}
                </h6>
              </div>
            ))}
          </div>
        </div>{" "}
        <br></br>
        <div className="row col-md-12 justify-content-between align-items-center">
          <div className="col-md-6 d-flex align-items-center">
            {" "}
            {/* Flex container added */}
            <button
              className="btn btn-yellow-primary mt-3 ml-2 flex-shrink-0"
              style={{
                borderRadius: "10px",
                color: "#25429c",
                fontWeight: "700",
                fontSize: "13px",
              }}
              data-target="#generatereport"
              data-toggle="modal"
            >
              <BsFileEarmarkExcelFill
                style={{
                  width: "24px",
                  height: "auto",
                  marginRight: "8px",
                  borderRadius: "8px !important",
                }}
              />
              Generate Report
            </button>
            <div className="d-flex align-items-center ml-3">
              {" "}
              {/* Adjust margin for spacing */}
              <p className="mt-4" style={{ color: "#254297" }}>
                <b>
                  {" "}
                  <BsClockFill /> &nbsp; Last Report Generated On: &nbsp;
                </b>
                <span style={{ fontWeight: 600 }}>
                  {" "}
                  {lastItem ? formatDate1(lastItem.i_ts) : "No Data Available"}
                </span>
              </p>
            </div>
            <div className="col-md-3 text-center mt-3">
              <button
                className="btn btn-yellow-primary"
                style={{
                  borderRadius: "10px",
                  color: "#25429c",
                  fontWeight: "700",
                  fontSize: "13px",
                }}
                data-target="#ViewHistoryPopUp"
                data-toggle="modal"
              >
                <BsListStars
                  style={{
                    width: "24px",
                    height: "auto",
                    marginRight: "8px",
                  }}
                />
                View History
              </button>
            </div>
          </div>

          <div className="col-md-3 text-center mt-3">
            <div style={{ position: "relative", display: "inline-block" }}>
              <button
                className="btn btn-yellow-primary"
                style={{
                  borderRadius: "10px",
                  color: "#25429c",
                  fontWeight: "700",
                  fontSize: "13px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingRight: "10px",
                }}
                onClick={() => setShowDropdown(!showDropdown)} // Toggle dropdown
              >
                Last Month Report
                <FaChevronDown style={{ marginLeft: "8px" }} />{" "}
                {/* Dropdown Icon */}
              </button>

              {showDropdown && (
                <div
                  style={{
                    position: "absolute",
                    top: "40px",
                    left: "0",
                    width: "200px",
                    border: "1px solid #ccc",
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    padding: "10px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    zIndex: "1",
                  }}
                >
                  <form>
                    {lastTwoMonths.map((month, index) => (
                      <div key={index} style={{ marginBottom: "8px" }}>
                        <label
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <input
                            type="radio"
                            name="month"
                            value={month}
                            checked={selectedMonth === month}
                            onChange={() => handleSelection(month)} // Select month
                            style={{ marginRight: "8px" }}
                          />
                          {month}
                        </label>
                      </div>
                    ))}
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
        <br />
        {showmesage && (
          <h3 className="text-center pt-5 pb-5" style={{ color: "red" }}>
            No Data Found
          </h3>
        )}
        <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
        <div
          className="modal fade"
          id="ViewHistoryPopUp"
          role="dialog"
          aria-labelledby="exampleModalLabel1"
          aria-hidden="true"
          style={{ overflowY: "scroll" }}
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body modal-body-bgclr pl-3 pr-3 pt-0">
                <div className="popup-head mb-3">
                  <div className="row cancel-main-heading">
                    <div className="col-11 p-0">
                      <div className="text-center mb-2 mt-3 ">
                        <h3 className="Cancellation_heading pb-3">History</h3>
                      </div>
                    </div>
                    <div className="col-1">
                      <div
                        className="text-right modal_close_btn"
                        data-dismiss="modal"
                        aria-label="Close"
                        style={{ top: "-22px", right: "-16px" }}
                      >
                        <span aria-hidden="true" className="close_Btn">
                          ×
                        </span>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="p-3">
                    <table className="table-bordered">
                      <thead>
                        <tr>
                          <th
                            className="text-center"
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              backgroundColor: "#25429C",
                              color: "#FFFF",
                            }}
                          >
                            Requested By
                          </th>
                          <th
                            className="text-center"
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              backgroundColor: "#25429C",
                              color: "#FFFF",
                            }}
                          >
                            Requestee Email
                          </th>
                          <th
                            className="text-center"
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              backgroundColor: "#25429C",
                              color: "#FFFF",
                            }}
                          >
                            Requested On
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems &&
                          currentItems.map((item, i) => (
                            <tr key={i}>
                              <td>{item.name}</td>
                              <td>{item.email}</td>
                              <td>{formatDate1(item.i_ts)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="pagination mt-3" style={{ float: "right" }}>
                      <button
                        className="btn btn-yellow-primary"
                        onClick={() => handlePageChange("prev")}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <span className="mt-2">
                        &nbsp; Page {currentPage} of {totalPages} &nbsp;
                      </span>
                      <button
                        className="btn btn-yellow-primary"
                        onClick={() => handlePageChange("next")}
                        disabled={currentPage === totalPages}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="generatereport"
          role="dialog"
          aria-labelledby="exampleModalLabel1"
          aria-hidden="true"
          style={{ overflowY: "scroll" }}
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body modal-body-bgclr pl-3 pr-3 pt-0">
                <div className="popup-head mb-3">
                  <div className="row cancel-main-heading">
                    <div className="col-11 p-0">
                      <div className="text-center mb-2 mt-3 ">
                        <h3 className="Cancellation_heading pb-3">
                          Generate Report
                        </h3>
                      </div>
                    </div>
                    <div className="col-1">
                      <div
                        className="text-right modal_close_btn"
                        data-dismiss="modal"
                        aria-label="Close"
                        style={{ top: "-22px", right: "-16px" }}
                      >
                        <span aria-hidden="true" className="close_Btn">
                          ×
                        </span>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="p-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="mb-3 me-2 flex-grow-1">
                        <label
                          htmlFor="startDate"
                          className="form-label"
                          style={{ fontWeight: "600" }}
                        >
                          Start Date
                        </label>
                        <div>
                          <Calendar
                            id="startDate"
                            name="startDate"
                            className="form-control input-height"
                            format="dd/MM/yyyy"
                            maxDate={new Date()}
                            selectedDate={selectedDate.startDate}
                            onDateChange={(date) =>
                              handleDateChange(date, "startDate")
                            }
                          />
                          {error.startDate && (
                            <span className="text-danger">
                              Start Date is required.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="mb-3 me-2 flex-grow-1 ml-2">
                        <label
                          htmlFor="endDate"
                          className="form-label"
                          style={{ fontWeight: "600" }}
                        >
                          End Date
                        </label>
                        <div>
                          <Calendar
                            id="endDate"
                            name="endDate"
                            className="form-control input-height"
                            format="dd/MM/yyyy"
                            minDate={selectedDate.startDate || null}
                            maxDate={new Date()}
                            selectedDate={selectedDate.endDate}
                            onDateChange={(date) =>
                              handleDateChange(date, "endDate")
                            }
                          />
                          {error.endDate && (
                            <span className="text-danger">
                              End Date is required.
                            </span>
                          )}
                        </div>
                      </div>
                      <button
                        className="btn   btn-yellow-primary mt-3  ml-2 flex-shrink-0"
                        style={{
                          borderRadius: "10px",
                          color: "#25429c",
                          fontWeight: "700",
                          fontSize: "13px",
                        }}
                        onClick={validateAndFetchReport}
                      >
                        Generate
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
