import React, { useState, useEffect } from "react";
import Stepper from "bs-stepper";
import "bs-stepper/dist/css/bs-stepper.min.css";
import StepDetailsComponent from "./StepDetailsComponent";
import CurrentApplicationForm from "./CurrentApplicationForm";
import { requestType, StepperEnum, UploadTypeEnum } from "../../../utils/Enums";
import { post } from "../../../effects/api/api.common";
import {
  GET_RE_APPLICATION_VIEW,
  GET_STORE_INCUBATOR_DATA,
  GET_Re_APPLICATION_LIST,
} from "../../../constants/api";
import { FileUploadModel } from "../../../models/FileUploadModel";
import { createFile } from "../../../utils/CommonMethods";
import { Link } from "react-router-dom";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import { useHistory } from "react-router-dom";
import {
  GeneralIncubatorModel,
  TeamDetails,
} from "../../../models/incubator/GeneralIncubatorModel";
import { IIncubatorModel2 } from "./IncubatorModel2";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import IncubatorHeaderComponent from "../common/IncubatorHeaderComponent";
import FundRequiremntDtls from "./FundRequiremntDtls";
import { FundReapplyDtlsModel } from "../../../models/incubator/FundReapplyDtlsModel";

interface IIncubatorProps {
  incubator_application_no: number;
  location: any;
  disabled: boolean;
}
interface IIncubatorState {
  stepperRef: any;
  name: string;
  applicationId: number;
  incubator: IIncubatorModel2;
  showIncubatorSuccessModal: boolean;
  showIncubatorErrorModal: boolean;
  // not in use but helps to call re-render method to bind other tabs
  currentStep: number;
  viewApplicationId: number;
  disabled: boolean;
}
let stepper: any = null;
let isSubmitted: boolean = false;
let formError: any;
const ReApplyIncComponent = (props: IIncubatorProps) => {
  const [name, setName] = useState("React");
  const [Application_number, setApplication_number] = useState("");
  const history = useHistory();
  const [stepperRef, setStepperRef] = useState(null);
  const [applicationId, setApplicationId] = useState("0");
  const [significantfileuploadArray, setsignificantfileuploadArray] =
    useState("");
  const [fulltimeEmployeesArray, setfulltimeEmployeesArray] = useState("");
  const [pitchdeckArray, setPitchdeckArray] = useState("");
  const [performanceArray, setPerformanceArray] = useState("");
  const [otherdocArray, setOtherdocArray] = useState("");
  const [ucfilesArray, setucfilesArray] = useState("");
  const [AuditedfilesArray, setAuditedfilesArray] = useState("");
  const [CurrentUploadfilesArray, setCurrentUploadfilesArray] = useState("");
  const [loader, setLoader] = useState(false);
  const [incubator, setIncubator] = useState({
    GeneralDetail: new GeneralIncubatorModel(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      [],
      ""
    ),
    // IncubatorTeam: new IncubatorSchemePartOne("", "", "", "", "", "", "", "", "", "","","","",""),
    // IncubatorDetails: new IncubatorSchemePartTwo(
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    // ),
    FundDetails: new FundReapplyDtlsModel("", "", "", "", ""),
    // OtherDetails: new IncubatorOthersModel(
    //   "",
    //   [],
    //   [],
    //   [],
    //   [],
    //   false,
    //   false,
    //   false,
    //   false,
    //   []
    // ),
  });
  const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] =
    useState(false);
  const [showIncubatorErrorModal, setShowIncubatorErrorModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(StepperEnum.GeneralDetail);
  const [disabled, setDisabled] = useState(false);
  const [validator, setValidator] = useState({
    GeneralDetail: [],
    FundDetails: [],
  });
  const [teamError, setTeamError] = useState<Array<number>>([]);
  const [stopSubmit, setStopSubmit] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [isfalse, setIsFalse] = useState(false);
  const [compl, setCompl] = useState("");

  useEffect(() => {
    // console.log(props.location, "######")
    if (typeof props.location.state != "undefined") {
      if (
        props.location.state.application_id != 0 &&
        props.location.state.disabled == true
      ) {
        //////console.log("IF");
        setDisabled(true);
        setApplicationId(props.location.state.application_id);
        viewActiveApplication(props.location.state.application_id);
      } else if (
        props.location.state.application_id != 0 &&
        props.location.state.continue_editing == 1
      ) {
        //////console.log("ELSE_IF");
        setApplicationId(props.location.state.application_id);
        viewActiveApplication(props.location.state.application_id);
        setCompl(props.location.state.MarkedLabel);
        setValidator({
          GeneralDetail: [],
          FundDetails: [],
        });
        setTeamError([]);
      } else {
        setApplicationId(props.location.state.application_id);
      }
    } else {
      history.push("/incubator_status");
    }
    stepper = new Stepper(
      document.querySelector("#incubatorstepper") as Element,
      {
        linear: false,
        animation: true,
      }
    );
    // fetch application records for each tabs and convert it into respective models
    // getActiveApplication();
    setStepperRef(stepper);
    window.scrollTo(0, 0);
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const userId = userInfo.id;
    post(GET_Re_APPLICATION_LIST, { logged_user_id: userId }).then(
      (response: any) => {
        viewActiveApplication(response.data.data[0].incubator_application_no);
        // //console.log(response.data.data[0].incubator_application_no,"dataaaa")
        setApplicationId(response.data.data[0].incubator_application_no);
      }
    );
  }, []);
  // Set disabled flag when we can view application when it's submitted
  const setDisabledFlag = (flag: boolean) => {
    setDisabled(true);
  };
  const handleSubmittedApplication = () => {
    window.location.reload();
  };
  //view active application
  const viewActiveApplication = (incubator_application_no: any) => {
    post(GET_RE_APPLICATION_VIEW, {
      incubator_application_no: incubator_application_no,
    }).then(async (response: any) => {
      // //console.log(response,"responseeeeeeeeeee")
      if (!response || !response.data) return;
      if (response.data.data[0]) {
        // 1st Step
        bindGeneralDetail(response, incubator);
        // 2nd Step
        bindIncubatorTeam(response, incubator);
        // 3rd Step
        bindIncubatorDetail(response, incubator);
        // 4th Step
        bindFundDetail(response, incubator);
        setIncubator((prevState: IIncubatorModel2) => {
          const updatedState = { ...prevState, incubator };
          return updatedState;
        });
      }
    });
  };

  const onSave = (
    stepperIndex: number,
    object: any,
    request: any,
    oldStatus: any
  ) => {
    console.log("oldStatus", oldStatus);
    switch (stepperIndex) {
      case StepperEnum.GeneralDetail:
        incubator.GeneralDetail = object;
        setIncubator(incubator);
        break;
      case StepperEnum.FundUtilization:
        incubator.FundDetails = object;
        break;
    }
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const userId = userInfo.id;
    const formData = new FormData();
    formData.append("logged_user_id", userId);
    formData.append(
      "incubator_application_no",
      applicationId == "0" ? "0" : applicationId
    );
    const Re_apply_pitchdeck_upload_Filetype =
      typeof incubator.GeneralDetail.Re_apply_pitchdeck_upload;
    const pitchdecksplice =
      incubator.GeneralDetail.Re_apply_pitchdeck_upload_get;
    if (
      incubator.GeneralDetail.Re_apply_pitchdeck_upload.length > 0 &&
      Re_apply_pitchdeck_upload_Filetype != "string"
    ) {
      for (
        var j = 0;
        j < incubator.GeneralDetail.Re_apply_pitchdeck_upload.length;
        j++
      ) {
        formData.append(
          "Re_apply_pitchdeck_upload",
          incubator.GeneralDetail.Re_apply_pitchdeck_upload[j]
        );
      }
      if (pitchdeckArray.length > 0) {
        //////console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! ASSIGNING DATA FOR GET !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
        formData.append("Re_apply_pitchdeck_upload_get", pitchdeckArray);
        formData.append("Re_apply_pitchdeck_upload_slice", pitchdecksplice);
      } else {
        //////console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@ ASSIGNING DATA FOR GET IN ELSE @@@@@@@@@@@@@@@@@@@@@@@@@@@@ ")
        formData.append("Re_apply_pitchdeck_upload_get", pitchdeckArray);
        formData.append("Re_apply_pitchdeck_upload_slice", pitchdecksplice);
      }
    } else {
      formData.append("Re_apply_pitchdeck_upload", "0");
      formData.append("Re_apply_pitchdeck_upload_get", pitchdeckArray);
      formData.append("Re_apply_pitchdeck_upload_slice", pitchdecksplice);
    }
    //ptch deck code end
    //performance upload code starts
    const Performance_Upload_Filetype =
      typeof incubator.GeneralDetail.Performance_Upload;
    const performancesplice = incubator.GeneralDetail.Performance_Upload_get;
    if (
      incubator.GeneralDetail.Performance_Upload.length > 0 &&
      Performance_Upload_Filetype != "string"
    ) {
      for (
        var j = 0;
        j < incubator.GeneralDetail.Performance_Upload.length;
        j++
      ) {
        formData.append(
          "Performance_Upload",
          incubator.GeneralDetail.Performance_Upload[j]
        );
      }
      if (performanceArray.length > 0) {
        //////console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! ASSIGNING DATA FOR GET !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
        formData.append("Performance_Upload_get", performanceArray);
        formData.append("Performance_Upload_slice", performancesplice);
      } else {
        //////console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@ ASSIGNING DATA FOR GET IN ELSE @@@@@@@@@@@@@@@@@@@@@@@@@@@@ ")
        formData.append("Performance_Upload_get", performanceArray);
        formData.append("Performance_Upload_slice", performancesplice);
      }
    } else {
      formData.append("Performance_Upload", "0");
      formData.append("Performance_Upload_get", performanceArray);
      formData.append("Performance_Upload_slice", performancesplice);
    }
    //performance upload end
    //other document upload code starts
    const Other_document_upload_Filetype =
      typeof incubator.GeneralDetail.Other_document_upload;
    const otherdocsplice = incubator.GeneralDetail.Other_document_upload_get;
    if (
      incubator.GeneralDetail.Other_document_upload.length > 0 &&
      Other_document_upload_Filetype != "string"
    ) {
      for (
        var j = 0;
        j < incubator.GeneralDetail.Other_document_upload.length;
        j++
      ) {
        formData.append(
          "Other_document_upload",
          incubator.GeneralDetail.Other_document_upload[j]
        );
      }
      if (otherdocArray.length > 0) {
        //////console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! ASSIGNING DATA FOR GET !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
        formData.append("Other_document_upload_get", otherdocArray);
        formData.append("Other_document_upload_slice", otherdocsplice);
      } else {
        //////console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@ ASSIGNING DATA FOR GET IN ELSE @@@@@@@@@@@@@@@@@@@@@@@@@@@@ ")
        formData.append("Other_document_upload_get", otherdocArray);
        formData.append("Other_document_upload_slice", otherdocsplice);
      }
    } else {
      formData.append("Other_document_upload", "0");
      formData.append("Other_document_upload_get", otherdocArray);
      formData.append("Other_document_upload_slice", otherdocsplice);
    }
    //other document upload code ends
    formData.append(
      "No_startups_incubated",
      incubator.GeneralDetail.No_startups_incubated == "0"
        ? 0
        : incubator.GeneralDetail.No_startups_incubated
    );
    formData.append(
      "No_startups_graduated",
      incubator.GeneralDetail.No_startups_graduated == "0"
        ? 0
        : incubator.GeneralDetail.No_startups_graduated
    );
    formData.append(
      "No_startups_raised",
      incubator.GeneralDetail.No_startups_raised == "0"
        ? 0
        : incubator.GeneralDetail.No_startups_raised
    );
    formData.append(
      "No_startups_crossed",
      incubator.GeneralDetail.No_startups_crossed == "0"
        ? 0
        : incubator.GeneralDetail.No_startups_crossed
    );
    formData.append(
      "survival_rate_startups",
      incubator.GeneralDetail.survival_rate_startups == "0"
        ? 0
        : incubator.GeneralDetail.survival_rate_startups
    );
    formData.append(
      "Investment_agreements_signed",
      incubator.GeneralDetail.Investment_agreements_signed == "0"
        ? 0
        : incubator.GeneralDetail.Investment_agreements_signed
    );
    formData.append(
      "No_startups_invested",
      incubator.GeneralDetail.No_startups_invested == "0"
        ? 0
        : incubator.GeneralDetail.No_startups_invested
    );
    formData.append(
      "allocated_incubatees",
      incubator.GeneralDetail.allocated_incubatees == "0"
        ? 0
        : incubator.GeneralDetail.allocated_incubatees
    );
    formData.append(
      "external_sources",
      incubator.GeneralDetail.external_sources == "0"
        ? 0
        : incubator.GeneralDetail.external_sources
    );
    formData.append(
      "No_mentors_actively",
      incubator.GeneralDetail.No_mentors_actively == "0"
        ? 0
        : incubator.GeneralDetail.No_mentors_actively
    );
    formData.append(
      "allocated_startup_month",
      incubator.GeneralDetail.allocated_startup_month == "0"
        ? 0
        : incubator.GeneralDetail.allocated_startup_month
    );
    formData.append(
      "No_patents_registered",
      incubator.GeneralDetail.No_patents_registered == "0"
        ? 0
        : incubator.GeneralDetail.No_patents_registered
    );
    formData.append(
      "No_patents_copyrights",
      incubator.GeneralDetail.No_patents_copyrights == "0"
        ? 0
        : incubator.GeneralDetail.No_patents_copyrights
    );
    formData.append(
      "No_trademarks_registered",
      incubator.GeneralDetail.No_trademarks_registered == "0"
        ? 0
        : incubator.GeneralDetail.No_trademarks_registered
    );
    formData.append(
      "Corporate_connects_facilitated",
      incubator.GeneralDetail.Corporate_connects_facilitated == "0"
        ? 0
        : incubator.GeneralDetail.Corporate_connects_facilitated
    );
    formData.append(
      "Events_stakeholder_engagements",
      incubator.GeneralDetail.Events_stakeholder_engagements == "0"
        ? 0
        : incubator.GeneralDetail.Events_stakeholder_engagements
    );
    formData.append(
      "Participation_Event",
      incubator.GeneralDetail.Participation_Event == "0"
        ? 0
        : incubator.GeneralDetail.Participation_Event
    );
    formData.append(
      "Name_Background_current",
      incubator.GeneralDetail.Name_Background_current == ""
        ? ""
        : incubator.GeneralDetail.Name_Background_current
    );
    formData.append(
      "team_data",
      JSON.stringify(incubator.GeneralDetail.team_data)
    );
    // formData.append("mteam_name", (incubator.GeneralDetail.mteam_name == 0 ? 0 : incubator.GeneralDetail.mteam_name));
    formData.append(
      "Fund_Utilization_Details",
      incubator.GeneralDetail.Fund_Utilization_Details == 0
        ? 0
        : incubator.GeneralDetail.Fund_Utilization_Details
    );
    formData.append(
      "fulltime_employees_re_apply",
      incubator.GeneralDetail.fulltime_employees_re_apply == "0"
        ? 0
        : incubator.GeneralDetail.fulltime_employees_re_apply
    );
    formData.append(
      "quantum_fund_required_re_apply",
      incubator.FundDetails.quantum_fund_required_re_apply == ""
        ? null
        : incubator.FundDetails.quantum_fund_required_re_apply
    );
    formData.append(
      "no_fund_benifitiaries_1yr_re_apply",
      incubator.FundDetails.no_fund_benifitiaries_1yr_re_apply == 0
        ? 0
        : incubator.FundDetails.no_fund_benifitiaries_1yr_re_apply
    );
    formData.append(
      "no_fund_benifitiaries_2yr_re_apply",
      incubator.FundDetails.no_fund_benifitiaries_2yr_re_apply == 0
        ? 0
        : incubator.FundDetails.no_fund_benifitiaries_2yr_re_apply
    );
    formData.append(
      "no_fund_benifitiaries_3yr_re_apply",
      incubator.FundDetails.no_fund_benifitiaries_3yr_re_apply == 0
        ? 0
        : incubator.FundDetails.no_fund_benifitiaries_3yr_re_apply
    );
    formData.append("status_id", request);
    formData.append("oldStatus_id", oldStatus);
    let check: boolean = false;
    //console.log("@@@",validator)
    // console.log(requestType, "")
    if (
      request === requestType.submit ||
      (request === requestType.propertyChange && isSubmitted)
    ) {
      isSubmitted = true;
      formError = validateIncubator(incubator);
      check = request === requestType.submit && formError.error;
    }
    if (request !== requestType.submit) {
      incubator.FundDetails.action = "";
    }
    const hasFormError =
      formError &&
      (formError.errors?.GeneralDetail.length ||
        formError.errors?.FundDetails.length);
    if (formError && hasFormError > 0) {
      setValidator(formError.errors);
      // return;
    } else if (
      isSubmitted &&
      request === requestType.propertyChange &&
      hasFormError > 0
    ) {
      setValidator(formError.errors);
    } else {
      check = false;
    }
    if (
      (request == requestType.submit && !check) ||
      request == requestType.save
    ) {
      setStopSubmit(false);
      setDisabled(true);
      console.log("STATUS", request);
      // return;
      post(GET_STORE_INCUBATOR_DATA, formData).then((response: any) => {
        if (!response || !response.data) {
          return;
        }
        setDisabled(false);
        formError = null;
        isSubmitted = false;
        if (response.data.data != 0) {
          setApplicationId(response.data.data.incubator_application_no);
        }
        if (response.status === 200) {
          if (
            incubator.FundDetails.action &&
            incubator.FundDetails.action == "submit"
          ) {
            setShowIncubatorSuccessModal(true);
          }
          post(GET_Re_APPLICATION_LIST, { logged_user_id: userId }).then(
            (response: any) => {
              viewActiveApplication(
                response.data.data[0].incubator_application_no
              );
            }
          );
        } else {
          if (
            incubator.FundDetails.action &&
            incubator.FundDetails.action == ""
          ) {
            setShowIncubatorErrorModal(true);
          }
        }
      });
    }
  };
  const validateIncubator = (data: any) => {
    setValidator({
      GeneralDetail: [],
      FundDetails: [],
    });
    let errors: any = {
      GeneralDetail: [],
      IncubatorTeam: [],
      IncubatorDetails: [],
      FundDetails: [],
    };
    const parentKeys = Object.keys(data);
    let nonMandatoryFields = [
      "website_url",
      "facebook_enable",
      "linkedin_enable",
      "twitter_enable",
      "youtube_enable",
      "facebook_link",
      "twitter_link",
      "linkedin_link",
      "youtube_link",
      "no_of_patents",
      "no_of_patents_applicable",
      "no_of_copyrights",
      "no_of_copyrights_applicable",
      "no_of_trademarks",
      "no_of_trademarks_applicable",
      "relavent_document_other",
      "fund_disburse_using_3rd_party",
      "action",
      "mentors_average_hours",
      "incubator_name",
      "startups_invested_total_corpus_applicable",
      "total_investments_raised_incubatees_applicable",
      "mentors_average_hours_applicable",
      "startups_invested_applicable",
      "mentors_no_engaged_applicable",
      "corporate_facilitated_applicable",
      "event_engagements_applicable",
      "participation_other_events_applicable",
      "fulltime_employees_no",
      "upload_documents_incubation_support",
      "upload_documents_mentorship_provided",
      "upload_documents_composition_incubator",
      "upload_documents_events_organized",
      "relavent_document_pitchdeck",
      "startups_invested",
      "mentors_no_engaged",
      "mentors_no_of_ip",
      "no_fund_benifitiaries_1yr",
      "no_fund_benifitiaries_2yr",
      "no_fund_benifitiaries_3yr",
      "startups_no_incubated",
      "startups_no_graduated",
      "startups_no_investments",
      "startups_no_revenue_year",
      "startups_survival_rate",
      "investments_between_incubator_startups",
      "corporate_facilitated",
      "event_engagements",
      "participation_other_events",
      "startups_invested_total_corpus",
      "total_investments_raised_incubatees",
      "No_Startup_Applications_Received",
      "open_applications",
      "No_Startup_Applications_Reviewed",
      "No_Startup_Applications_Selected",
      "No_Startup_Applications_Allotted",
      "No_Startup_Applications_Cancelled",
      "No_Startup_Applications_Closed",
      "No_industries_represented",
      "No_States_represented",
      "Cumulative_Monetary_Return",
      "List_significant_facilities_upload_get",
      "List_significant_facilities_upload_slice",
      "Utilization_Certificate_Upload_get",
      "Utilization_Certificate_Upload_slice",
      "Audited_statement_expenditure_Upload_get",
      "Audited_statement_expenditure_Upload_slice",
      "Current_Incubator_Team_Upload_get",
      "Current_Incubator_Team_Upload_slice",
      "No_full_time_employees_upload_get",
      "No_full_time_employees_upload_slice",
      "re_apply_upload_documents_composition_incubator",
      "re_apply_upload_documents_incubation_support",
      "re_apply_upload_documents_mentorship_provided",
      "re_apply_upload_documents_events_organized",
      "checkbox_events_organized",
      "checkbox_composition_incubator",
      "checkbox_incubation_support",
      "checkbox_mentorship_provided",
      "no_fund_benifitiaries_1yr_re_apply",
      "no_fund_benifitiaries_2yr_re_apply",
      "no_fund_benifitiaries_3yr_re_apply",
      "List_significant_facilities_upload_slice",
      "List_significant_facilities_upload_get",
      "Re_Apply_relavant_excel",
      "Re_apply_relavent_document_pitchdeck",
      "Re_apply_pitchdeck_upload_get",
      "Re_apply_pitchdeck_upload_slice",
      "Other_document_upload_get",
      "Other_document_upload_slice",
      "Performance_Upload_get",
      "Performance_Upload_slice",
      "fulltime_employees_re_apply",
      "Fund_Utilization_Details",
      "mteam_name",
    ];
    let error = false;
    parentKeys.map((key, ind) => {
      Object.keys(data[key]).map((item) => {
        if (nonMandatoryFields.indexOf(item) < 0) {
          // if (item === "team_data") {
          //   let uniqueArray: any = [];
          //   data[key][item].length &&
          //     data[key][item].map((team: TeamDetails, ind: number) => {
          //       if (!team.team_name.length || team.no_employee < 0) {
          //         uniqueArray.push(ind);
          //         errors[key].push('team_data');
          //       } else {
          //         uniqueArray.splice(uniqueArray.indexOf(ind), 1);
          //       }
          //     });
          //   let tempArray: any = [];
          //   uniqueArray.length &&
          //     uniqueArray.map(function (item: number, pos: number) {
          //       if (tempArray.indexOf(item) < 0) {
          //         tempArray.push(item);
          //       }
          //     });
          //   setTeamError(uniqueArray);
          // }
          if (item === "quantum_fund_required_re_apply") {
            if (data[key][item] != "" && data[key][item] <= 50000000) {
              nonMandatoryFields.push("quantum_fund_required_re_apply");
            } else {
              errors[key].push(item);
            }
          }

          if (item === "Re_apply_pitchdeck_upload") {
            //console.log("Re_apply_pitchdeck_upload",data[key][item])

            //console.log("Re_apply_pitchdeck_upload",data[key][item])
            if (
              data[key][item] !== undefined &&
              data[key][item] !== "0" &&
              data[key][item] !== null &&
              data[key][item] !== ""
            ) {
              // nonMandatoryFields.push("Re_apply_pitchdeck_upload");
            } else {
              errors[key].push(item);
              error = true;
            }
          }

          if (item === "Other_document_upload") {
            if (
              data[key][item] !== undefined &&
              data[key][item] !== "0" &&
              data[key][item] !== null &&
              data[key][item] !== ""
            ) {
              // nonMandatoryFields.push("Other_document_upload");
            } else {
              errors[key].push(item);
              error = true;
            }
          }

          if (item === "Performance_Upload") {
            if (
              data[key][item] !== undefined &&
              data[key][item] !== "0" &&
              data[key][item] !== null &&
              data[key][item] !== ""
            ) {
              // nonMandatoryFields.push("Performance_Upload");
            } else {
              errors[key].push(item);
              error = true;
            }
          }
          if (item === "team_data") {
            for (let i = 0; i < data[key][item].length; i++) {
              // console.log(data[key][item][i], "errorss")
              if (
                i == 0 &&
                (data[key][item][i].team_name == "" ||
                  data[key][item][i].team_name == null)
              ) {
                errors[key].push(item);
                error = true;
              }
            }
          }

          // if (request == requestType.submit) {
          //   let teamData = incubator.GeneralDetail.team_data
          //   for (let i = 0; i < teamData.length; i++) {
          //     if (teamData[i].team_name == '' || teamData[i].team_name == null) {
          //       return;
          //     }
          //   }
          // }

          if (item === "Name_Background_current") {
            if (data[key][item] == "") {
              errors[key].push(item);
              error = true;
            }
          }
          if (data[key][item] == null) {
            errors[key].push(item);
            error = true;
          }
          // if (Array.isArray(data[key][item]) && !data[key][item].length) {
          //   errors[key].indexOf(item) < 0 && errors[key].push(item);
          //   error = true;
          //   console.log("INTO IF",data[key][item])
          // } else if (!data[key][item]|| !data[key][item].toString().length) {
          //   console.log("INTO IF",data[key][item])
          //   errors[key].indexOf(item) < 0 && errors[key].push(item);
          //   error = true;
          // }
        }
      });
    });
    setValidator(errors);
    const errorObject = { error, errors: error ? errors : null };
    return errorObject;
  };
  const bindGeneralDetail = (response: any, incubator: IIncubatorModel2) => {
    console.log(response.data.data[0], "@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
    setsignificantfileuploadArray(
      response.data.data[0].List_significant_facilities_upload
    );
    setfulltimeEmployeesArray(
      response.data.data[0].No_full_time_employees_upload
    );
    setPitchdeckArray(response.data.data[0].Re_apply_pitchdeck_upload);
    setPerformanceArray(response.data.data[0].Performance_Upload);
    setOtherdocArray(response.data.data[0].Other_document_upload);
    incubator.GeneralDetail.No_startups_incubated =
      response.data.data[0].No_startups_incubated;
    incubator.GeneralDetail.No_startups_graduated =
      response.data.data[0].No_startups_graduated;
    incubator.GeneralDetail.No_startups_raised =
      response.data.data[0].No_startups_raised;
    incubator.GeneralDetail.No_startups_crossed =
      response.data.data[0].No_startups_crossed;
    incubator.GeneralDetail.survival_rate_startups =
      response.data.data[0].survival_rate_startups;
    incubator.GeneralDetail.Investment_agreements_signed =
      response.data.data[0].Investment_agreements_signed;
    incubator.GeneralDetail.No_startups_invested =
      response.data.data[0].No_startups_invested;
    incubator.GeneralDetail.allocated_incubatees =
      response.data.data[0].allocated_incubatees;
    incubator.GeneralDetail.external_sources =
      response.data.data[0].external_sources;
    incubator.GeneralDetail.No_mentors_actively =
      response.data.data[0].No_mentors_actively;
    incubator.GeneralDetail.allocated_startup_month =
      response.data.data[0].allocated_startup_month;
    incubator.GeneralDetail.No_patents_registered =
      response.data.data[0].No_patents_registered;
    incubator.GeneralDetail.No_patents_copyrights =
      response.data.data[0].No_patents_copyrights;
    incubator.GeneralDetail.No_trademarks_registered =
      response.data.data[0].No_trademarks_registered;
    incubator.GeneralDetail.Corporate_connects_facilitated =
      response.data.data[0].Corporate_connects_facilitated;
    incubator.GeneralDetail.Events_stakeholder_engagements =
      response.data.data[0].Events_stakeholder_engagements;
    incubator.GeneralDetail.Participation_Event =
      response.data.data[0].Participation_Event;
    incubator.GeneralDetail.Name_Background_current =
      response.data.data[0].Name_Background_current;
    incubator.GeneralDetail.fulltime_employees_re_apply =
      response.data.data[0].fulltime_employees_re_apply;
    incubator.GeneralDetail.Re_apply_pitchdeck_upload =
      response.data.data[0].Re_apply_pitchdeck_upload;
    incubator.GeneralDetail.Re_apply_pitchdeck_upload_get =
      response.data.data[0].Re_apply_pitchdeck_upload_get;
    incubator.GeneralDetail.Performance_Upload =
      response.data.data[0].Performance_Upload;
    incubator.GeneralDetail.Performance_Upload_get =
      response.data.data[0].Performance_Upload_get;
    incubator.GeneralDetail.Other_document_upload =
      response.data.data[0].Other_document_upload;
    incubator.GeneralDetail.Other_document_upload_get =
      response.data.data[0].Other_document_upload_get;
    incubator.GeneralDetail.Fund_Utilization_Details =
      response.data.data[0].Fund_Utilization_Details;
    incubator.GeneralDetail.team_data = response.data.data[0].team_data.length
      ? response.data.data[0].team_data
      : [new TeamDetails(0, "", 0)];
  };
  // Bind IncubatorTeam Step 2 response
  const bindIncubatorTeam = (response: any, incubator: IIncubatorModel2) => {
    setucfilesArray(response.data.data[0].Utilization_Certificate_Upload);
    setAuditedfilesArray(
      response.data.data[0].Audited_statement_expenditure_Upload
    );
  };
  // Bind IncubatorDetail Step 3 response
  const bindIncubatorDetail = (response: any, incubator: IIncubatorModel2) => {
    setCurrentUploadfilesArray(
      response.data.data[0].Current_Incubator_Team_Upload
    );
  };
  // Bind FundDetail Step 4 response
  const bindFundDetail = (response: any, incubator: IIncubatorModel2) => {
    incubator.FundDetails.quantum_fund_required_re_apply =
      response.data.data[0].quantum_fund_required_re_apply;
    incubator.FundDetails.no_fund_benifitiaries_1yr_re_apply =
      response.data.data[0].no_fund_benifitiaries_1yr_re_apply;
    incubator.FundDetails.no_fund_benifitiaries_2yr_re_apply =
      response.data.data[0].no_fund_benifitiaries_2yr_re_apply;
    incubator.FundDetails.no_fund_benifitiaries_3yr_re_apply =
      response.data.data[0].no_fund_benifitiaries_3yr_re_apply;
  };

  return (
    <div>
      <IncubatorHeaderComponent />
      <div className="app-inside app-incubator">
        <div
          className="container-fluid about-heading app-banner-inside"
          id="reapply_main"
        >
          <div>
            <div className="text-center main-heading">
              Incubator Reapplication
            </div>
            <div className="text-center sub-heading">
              All form fields are <b>mandatory</b>, unless mentioned as{" "}
              <b>‘optional’</b>
              <br />
              Application form to be filled in English language
            </div>
          </div>
        </div>
        <div className="container stepper-container">
          <div className="app-inside-content">
            <div className="row justify-content-center">
              <div className="col-xs-12 col-sm-12">
                <div id="incubatorstepper" className="bs-stepper">
                  {/* Incubator For Stepper Steps Header */}
                  <StepDetailsComponent
                    teamError={teamError}
                    errors={validator}
                    onClick={(currentStep: number) => {
                      setCurrentStep(currentStep);
                    }}
                  />

                  {/* Incubator For Stepper Steps Content */}
                  <div className="bs-stepper-content">
                    {/* Check whether to make a common reusable component for Dialog */}
                    {showIncubatorSuccessModal && (
                      // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                      <>
                        <div className="modal-backdrop fade show"></div>

                        <div
                          className="modal align-middle modal-sisfs d-block"
                          id="FormSubmissionSuccess"
                        >
                          <div className="modal-dialog modal-dialog-centered modal-md">
                            <div className="modal-content">
                              <div className="modal-body text-center">
                                <i className="fa fa-check-circle-o"></i>
                                <h3 className="modal-message">Thank You!</h3>
                                <div className="modal-text">
                                  Your application was successfully submitted.
                                  We’ll be in touch with you, soon!
                                </div>
                                <div className="modal-action">
                                  <button className="btn btn-sisfs btn-yellow-primary">
                                    {/* incubator_re_apply */}
                                    <Link
                                      to={{
                                        pathname: "/incubator_status",
                                        state: { perform_reload: "true" },
                                      }}
                                    >
                                      Continue
                                    </Link>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {showIncubatorErrorModal && (
                      // <FormSubmissionError data-toggle="modal" data-target="#FormSubmissionError"/>
                      <>
                        <div className="modal-backdrop fade show"></div>
                        <div
                          className="modal align-middle modal-sisfs d-block"
                          id="FormSubmissionError"
                        >
                          <div className="modal-dialog modal-dialog-centered modal-md">
                            <div className="modal-content">
                              <div className="modal-body text-center">
                                <i className="fa fa-times-circle-o"></i>
                                <h3 className="modal-message">
                                  Oops! Something is Wrong
                                </h3>
                                <div className="modal-text">
                                  <ul>
                                    <li>
                                      {" "}
                                      You have either missed out on some
                                      mandatory fields{" "}
                                    </li>
                                    <li>Entered the data wrongly</li>
                                  </ul>
                                </div>
                                <div className="modal-action">
                                  <button className="btn btn-sisfs btn-yellow-primary">
                                    <Link to="/re-apply">Ok Got It!</Link>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {loader && (
                      <div
                        className="spinner-border custom-spinner"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                    <fieldset disabled={disabled}>
                      <CurrentApplicationForm
                        stepper={stepperRef}
                        onStepSave={onSave}
                        generalDetailModel={incubator.GeneralDetail}
                        applicationId={applicationId}
                        errors={validator.GeneralDetail}
                        teamError={teamError}
                        oldStatus={compl}
                      />
                      <FundRequiremntDtls
                        stepper={stepperRef}
                        onStepSave={onSave}
                        fundDetailsModel={incubator.FundDetails}
                        errors={validator.FundDetails}
                        disabled={disabled}
                        applicationId={applicationId}
                        oldStatus={compl}
                      />
                    </fieldset>
                    {stopSubmit && (
                      <ConfirmationDialog
                        headerText=""
                        bodyText="Only Incubators not disbursing seed fund to incubatees using
                    funding from any third-party private entity are eligible"
                        onDialogClose={() => setStopSubmit(false)}
                        iconClass="fa fa-times-circle-o"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReApplyIncComponent;
